<template>
  <div class="usage-detailed" style="height: 100%">
    <div class="entity-header mb-12">
      <div class="d-flex align-center">
        <icon-button class="mr-6" :to="backRoute">
          <v-icon>mdi-arrow-left</v-icon>
        </icon-button>

        <div v-if="!isLoading" class="d-flex flex-column flex-md-row align-md-baseline">
          <h1 class="entity-header__title text-body-1 text-md-h4 font-weight-bold">
            {{ title }}
          </h1>
          <span class="ms-4 text--secondary">{{ unitInfo }}</span>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="d-flex justify-center align-center primary--text" style="height: 100%">
      <v-progress-circular indeterminate :size="50" color="primary" />
    </div>

    <div v-else>
      <div v-if="agreements.length">
        <view-title :remove-m-t="true">
          <slot name="label">
            {{ $t('usage.e-signed') }}
          </slot>
        </view-title>
        <agreements-list :agreements="agreements" @update-documents="updateDocuments" />
      </div>

      <rental-agreement v-if="isTenant" :documents="usage" :usage-id="usageId" @update-documents="updateDocuments" />
      <co-tenant v-if="isTenant" :documents="usage" :usage-id="usageId" @update-documents="updateDocuments" />

      <contract-with-landlords
        v-if="isOwner"
        :documents="usage"
        :usage-id="usageId"
        @update-documents="updateDocuments"
      />
      <property-managment-letter
        v-if="isOwner"
        :documents="usage"
        :usage-id="usageId"
        @update-documents="updateDocuments"
      />

      <d-t-s-m-permit
        v-if="isOwner"
        :usage-id="usageId"
        :file="usage.dtcmPermitScan"
        @update-documents="updateDocuments"
      />

      <property-title-deed
        v-if="isOwner"
        :usage-id="usageId"
        :file="usage.propertyTitleDeedScan"
        @update-documents="updateDocuments"
      />
    </div>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';
import mediaService from '@/services/media';

import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

import { translateDate } from '@/utils/dateFormatting';

import format from 'date-fns/format';

// Components
import IconButton from '@/components/IconButton.vue';
import AgreementsList from '@/components/Usage/AgreementsList.vue';
import ContractWithLandlords from '@/components/Usage/ContractWithLandlords.vue';
import PropertyManagmentLetter from '@/components/Usage/PropertyManagmentLetter.vue';
import RentalAgreement from '@/components/Usage/RentalAgreement.vue';
import CoTenant from '@/components/Usage/CoTenant.vue';
import DTSMPermit from '@/components/Usage/DTSMPermit.vue';
import PropertyTitleDeed from '@/components/Usage/PropertyTitleDeed.vue';

// Constants
import { CLIENTS_DETAILED } from '@/constants/routes';
import { OWNER, TENANTS } from '@/constants/typesClients';

// Schema
import {
  contractWithLandlordsSchema,
  propertyManagmentLetterSchema,
  rentalAgreementSchema,
  coTenantSchema,
} from '@/schemas/contract.schema';

export default {
  name: 'UsageDetailed',

  components: {
    IconButton,
    ViewTitle: () => import('@/components/schema/ViewTitle.vue'),
    AgreementsList,
    ContractWithLandlords,
    PropertyManagmentLetter,
    RentalAgreement,
    CoTenant,
    DTSMPermit,
    PropertyTitleDeed,
  },

  props: {},

  data() {
    return {
      usageId: +this.$route.params.usageId,
      clientId: +this.$route.params.clientId,
      backRoute: this.$route.params.backRoute || {
        name: CLIENTS_DETAILED,
        params: { id: this.$route.params.clientId },
      },
      isLoading: false,
      usage: {},
      clientType: 'owner',
    };
  },

  computed: {
    title() {
      return `${this.usage.client?.firstName} ${this.usage.client?.lastName} ${this.$t('documents')}`;
    },

    unitInfo() {
      return this.usage.roomName
        ? `${this.usage.projectName}, ${this.usage.buildingName}, ${this.usage.unitName}, ${this.usage.roomName}`
        : `${this.usage.projectName}, ${this.usage.buildingName}, ${this.usage.unitName}`;
    },

    agreements() {
      return this.usage.agreements || [];
    },

    isOwner() {
      return this.clientType === OWNER;
    },

    isTenant() {
      return this.clientType === TENANTS;
    },
  },

  created() {
    this.isLoading = true;
    this.getUsageDocuments();
  },

  methods: {
    getUsageDocuments() {
      usersService.getDocuments(this.usageId).then(async usage => {
        this.clientType = usage.clientType;
        this.usage = await this.formatDocuments(usage);
        this.isLoading = false;
      });
    },

    async formatMedia(mediaFile) {
      const newFile = {
        id: 1,
        url: mediaFile,
      };

      newFile.name = decodeURIComponent(newFile.url)
        .split('/')
        .pop();

      const { url, blob } = await this.getPrivateMediaObject(newFile.url);

      newFile.url = url;
      newFile.type = blob.type;
      newFile.size = blob.size;
      return newFile;
    },
    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },

    formatDate(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L yyyy'));
      }
      return date;
    },

    isEmptyObject(obj) {
      if (typeof obj !== 'object') return false;

      const objValues = Object.values(obj);
      for (let i = 0; i < objValues.length; i += 1) {
        if (objValues[i]) return false;
      }

      return true;
    },

    async formatDocuments(documents) {
      const newDocuments = documents;
      newDocuments.contractScan = newDocuments.contractScan
        ? await this.formatMedia(newDocuments.contractScan)
        : newDocuments.contractScan;
      newDocuments.coTenantIdScan = newDocuments.coTenantIdScan
        ? await this.formatMedia(newDocuments.coTenantIdScan)
        : newDocuments.coTenantIdScan;
      newDocuments.dtcmPermitScan = newDocuments.dtcmPermitScan
        ? await this.formatMedia(newDocuments.dtcmPermitScan)
        : newDocuments.dtcmPermitScan;
      newDocuments.propertyTitleDeedScan = newDocuments.propertyTitleDeedScan
        ? await this.formatMedia(newDocuments.propertyTitleDeedScan)
        : newDocuments.propertyTitleDeedScan;
      newDocuments.contractWithClientScan = newDocuments.contractWithClientScan
        ? await this.formatMedia(newDocuments.contractWithClientScan)
        : newDocuments.contractWithClientScan;
      newDocuments.propertyManagementLetterScan = newDocuments.propertyManagementLetterScan
        ? await this.formatMedia(newDocuments.propertyManagementLetterScan)
        : newDocuments.propertyManagementLetterScan;

      return newDocuments;
    },

    async updateDocuments(documents) {
      if (!documents) {
        this.getUsageDocuments();
        return;
      }
      this.usage = await this.formatDocuments(documents);
    },
  },

  contractWithLandlordsSchema,
  propertyManagmentLetterSchema,
  rentalAgreementSchema,
  coTenantSchema,
};
</script>

<style lang="scss">
.document-table {
  tr:hover {
    background: transparent !important;
  }
}
</style>
