<template>
  <v-data-table class="document-table" :headers="headers" :items="items" hide-default-footer :loading="loading">
    <template v-for="slot in getItemSlots()" v-slot:[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps" />
    </template>
    <template #item.file="{ item }">
      <a v-if="item.file" class="text-decoration-none" :href="item.file.url" :download="item.file.name">
        {{ item.file.name }}
      </a>
      <span v-else>—</span>
    </template>
    <template #item.actions>
      <slot name="actions" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'DocumentTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getItemSlots() {
      return Object.keys(this.$scopedSlots);
    },
  },
};
</script>
